<h1 class="content-title tw-mt-0">Moneyball</h1>

<!-- search -->
<mat-card class="searchCard moneyballContainer tw-max-w-[1800px]">
  <mat-card-content>
    <div class="searchFields tw-m-0 tw-mb-4">
      <!-- search -->
      <div class="searchContainer tw-min-w-[20rem] tw-max-w-[30rem] tw-flex-1">
        <div *ngIf="searching" class="spinnerContainer">
          <mat-spinner diameter="20"></mat-spinner>
        </div>
        <mat-form-field class="companySearch tw-leading-8" appearance="outline">
          <mat-label>Company (3 char minimum)</mat-label>
          <input
            matInput
            placeholder="Search for a company"
            [formControl]="companyNameControl"
            required
            [matAutocomplete]="auto" />

          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let searchItem of searchCompanies | async"
              (click)="companyClicked($event, searchItem)">
              <div class="search-company-row">
                <div class="search-company-item">
                  <div class="search-company-name-line">
                    <div>{{ searchItem.company.name }}</div>
                    <div *ngIf="searchItem.company.linkedin">
                      <a
                        href="https://www.linkedin.com/company/{{searchItem.company.linkedin}}"
                        target="_blank">
                        <img src="assets/img/linkedin.png" />
                      </a>
                    </div>
                    <mat-chip-list>
                      <mat-chip class="chip-ticker" *ngIf="searchItem.company.ticker"
                        ><mat-icon>show_chart</mat-icon> {{searchItem.company.ticker}}</mat-chip
                      >
                    </mat-chip-list>
                  </div>
                  <div class="search-company-info-line">
                    <div *ngIf="searchItem.type == 'company'" class="search-company-info-type">
                      Company
                    </div>
                    <div
                      *ngIf="searchItem.type == 'group'"
                      class="search-company-info-type conglomerate">
                      <mat-icon>corporate_fare</mat-icon>Conglomerate
                    </div>
                    <div *ngIf="searchItem.company.industry">&#9679;</div>
                    <div *ngIf="searchItem.company.industry">{{searchItem.company.industry}}</div>
                    <div *ngIf="searchItem.type == 'group'">&#9679;</div>
                    <div *ngIf="searchItem.type == 'group'">
                      {{searchItem.company.grouped_company_count | number}} companies
                    </div>
                    <div *ngIf="searchItem.company.country">&#9679;</div>
                    <div *ngIf="searchItem.company.country">{{searchItem.company.country}}</div>
                  </div>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="tw-flex tw-items-center tw-gap-3" style="flex: 2 !important">
        <!-- date range -->
        <div
          class="rangePicker tw-flex tw-flex-col tw-min-w-52 tw-self-start tw-mb-[13px] tw-ml-auto">
          <label class="tw-font-medium tw-mb-[6px]">Date range: </label>
          <input
            type="text"
            [ranges]="ranges"
            name="daterange"
            alwaysShowCalendars="true"
            opens="left"
            ngxDaterangepickerMd
            [showDropdowns]="true"
            [(ngModel)]="selectedDates"
            (datesUpdated)="onDatesChanged()"
            class="form-control tw-p-2 tw-mb-1 tw-border tw-border-solid tw-border-gray-300 tw-rounded" />
        </div>

        <!-- buttons -->
        <button
          mat-raised-button
          color="primary"
          (click)="getResults()"
          [disabled]="!selectedCompany">
          Get Results
        </button>

        <button
          *ngIf="isAdmin | async"
          mat-raised-button
          color="primary"
          (click)="showPeople()"
          [disabled]="!oneMonth">
          Show Arrivals
        </button>
      </div>
    </div>

    <!-- recent searches -->
    <div *ngIf="recentSearches.length > 0">
      <div
        class="recentSearches tw-flex tw-items-center tw-overflow-hidden tw-whitespace-nowrap tw-w-full">
        <div class="tw-font-medium tw-mr-3">Recent Searches:</div>

        <div class="tw-flex tw-flex-1 tw-items-center tw-overflow-hidden ">
          <button
            mat-stroked-button
            color="primary"
            class="compact-btn tw-flex-shrink-0 tw-mr-3 tw-mb-1"
            *ngFor="let search of recentSearches"
            (click)="companyClicked($event, search)">
            <mat-icon *ngIf="search.type === 'group'" class="corp_building">corporate_fare</mat-icon
            >{{search.company.name}}
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- loading -->
<div *ngIf="loading" class="loading-container">
  <p class="loading-message">Loading data for {{companyHero}}...</p>
</div>

<!-- company info 3 cards -->
<div [hidden]="!showCharts" class="results tw-max-w-[1840px]">
  <div class="cols-3 tw-my-5">
    <mat-card>
      <mat-card-title>
        <mat-icon *ngIf="selectedCompanyGroups">corporate_fare</mat-icon>
        {{companyHero}}
      </mat-card-title>
      <mat-card-subtitle *ngIf="selectedCompanyGroups"
        >{{groupedCompanies.length}} subsidiary companies</mat-card-subtitle
      >
      <mat-card-content class="tw-flex tw-flex-wrap tw-gap-[6px]">
        <a
          *ngFor="let company of displayedCompanies; let last = last"
          href="javascript:void(0)"
          class="tw-truncate tw-text-ellipsis tw-max-w-[360px] tw-text-primary_purple-600 tw-font-medium hover:tw-underline tw-transition-all"
          (click)="companyClicked($event, {'company': company, 'type': 'company'})">
          {{company.name}}<ng-container *ngIf="!last">, </ng-container>
        </a>
        <!-- conditional btn to show/hide too many filter chips -->
        <button
          mat-stroked-button
          color="primary"
          *ngIf="groupedCompanies && groupedCompanies.length > displayCompaniesLimit"
          (click)="toggleShowMore()"
          class="tw-h-[24px] tw-leading-[20px] tw-self-start">
          <ng-container *ngIf="showMoreCompanies; else showMoreLabel">
            <span class="tw-flex tw-items-start">
              <mat-icon class="tw-text-[20px] tw-w-[20px] tw-h-[20px]">
                keyboard_arrow_left
              </mat-icon>
              <span>Show fewer</span>
            </span>
          </ng-container>
          <ng-template #showMoreLabel>
            <span class="tw-text-left">
              ...and
              <span id="num-badges" class="tw-font-semibold">
                {{ groupedCompanies.length - displayCompaniesLimit }}
              </span>
              more
            </span>
          </ng-template>
        </button>
      </mat-card-content>
    </mat-card>

    <!-- total coverage -->
    <mat-card>
      <mat-card-title>Total Coverage</mat-card-title>
      <mat-card-subtitle>People we track that have ever worked here</mat-card-subtitle>
      <mat-card-content class="bannerNumber tw-text-3xl tw-font-semibold">
        {{(allTimeEmployeeCount?.toLocaleString() || 'Loading...')}}
      </mat-card-content>
    </mat-card>

    <!-- current employees -->
    <mat-card>
      <mat-card-title>Current Employees</mat-card-title>
      <mat-card-subtitle>People we track currently working here</mat-card-subtitle>
      <mat-card-content class="bannerNumber tw-text-3xl tw-font-semibold">
        {{(currentEmployeeCount?.toLocaleString() || 'Loading...')}}
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div [hidden]="showCharts" class="results">
  <p class="tw-mt-8">Please select a company to see the data.</p>
</div>
<!-- all charts -->
<div [hidden]="!showCharts" class="results">
  <div class="tw-grid tw-gap-4 tw-grid-cols-3 tw-w-full tw-mt-7">
    <!-- Arrivals/Departures -->
    <mat-card class="tw-flex tw-flex-col tw-max-h-[500px] tw-col-span-3 xl:tw-col-span-2">
      <div class="chartHeader tw-flex tw-items-center">
        <mat-card-title>
          <div>Arrivals/Departures by</div>
          <mat-form-field appearance="outline">
            <mat-select
              [formControl]="selectedArrivalsDeparturesChartOption"
              (selectionChange)="arrDepChartOptionChange($event)">
              <mat-option *ngFor="let opt of arrivalsDeparturesChartOptions" [value]="opt">
                {{opt}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-title>

        <div class="downloadButtonContainer">
          <button
            [class.spinner]="arrDepDownloading"
            [disabled]="arrDepDownloading"
            mat-raised-button
            color="primary"
            (click)="downloadArrivalsDeparturesCSV()">
            Download
          </button>
        </div>
      </div>
      <mat-card-content>
        <highcharts-chart
          id="arrivalsDeparturesChart"
          [Highcharts]="Highcharts"
          [options]="arrDepChartNoSeries"
          oneToOne="true"
          showLoading="true"
          (chartInstance)="logChartInstance($event, 'arrivalsDeparturesChart')"
          class="tw-mx-0"></highcharts-chart>
      </mat-card-content>
    </mat-card>

    <!-- Top Talent Competition -->
    <mat-card class="tw-flex tw-flex-col tw-max-h-[500px] tw-col-span-3 xl:tw-col-span-1">
      <div class="chartHeader">
        <mat-card-title>
          <div>Versus Top Talent Competitors</div>
        </mat-card-title>
      </div>
      <mat-card-content>
        <a
          href="javascript:void(0)"
          class="tw-inline-block tw-text-primary_purple-600 tw-pb-2 hover:tw-underline"
          [class.tw-pointer-events-none]="battleCompanies.length === 0"
          [class.tw-opacity-50]="battleCompanies.length === 0"
          (click)="battleCompanies.length > 0 && goToBattle()">
          View Competitive Breakdowns
        </a>
        <div id="chart-container-headcount" class="tw-flex-1">
          <div id="competitionChartContainer" style="width: 100%; height: 100%"></div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Employee Count by -->
  <div class="cols-1 tw-my-5">
    <mat-card>
      <div class="chartHeader tw-flex tw-items-center">
        <mat-card-title>
          <div>Employee Count by</div>
          <mat-form-field appearance="outline">
            <mat-select
              [formControl]="selectedEmployeeCountChartOption"
              (selectionChange)="empCountOptionChange($event)">
              <mat-option *ngFor="let opt of employeeCountChartOptions" [value]="opt">
                {{opt}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-title>
        <button
          [class.spinner]="empCountDownloading"
          [disabled]="empCountDownloading"
          mat-raised-button
          color="primary"
          (click)="downloadEmployeeCountCSV()">
          Download
        </button>
      </div>
      <mat-card-content>
        <highcharts-chart
          id="employeeCountChart"
          [Highcharts]="Highcharts"
          [options]="empCountChartNoSeries"
          oneToOne="true"
          (chartInstance)="logChartInstance($event, 'employeeCountByOptions')"></highcharts-chart>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="cols-1 tw-my-5">
    <mat-card>
      <mat-card-title class="tw-flex tw-items-center tw-flex-wrap">
        <div>Historical Tenure by</div>
        <mat-form-field appearance="outline">
          <mat-select
            [formControl]="selectedHistoricalTenureChartOption"
            (selectionChange)="historicalTenureChartOptionChange()">
            <mat-option
              *ngFor="let opt of historicalTenureChartOptions"
              [value]="opt"
              >{{opt}}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <div class="sample-size-picker">
          <div>Minimum Sample Size: {{minTenureSampleSize}}</div>
          <mat-slider
            min="1"
            max="10"
            step="1"
            value="{{minTenureSampleSize}}"
            (input)="onMinTenureSampleSizeChanged($event)">
            <input matSliderThumb />
          </mat-slider>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Show Employees</mat-label>
          <mat-select
            [formControl]="selectedTenureChartStatusOption"
            (selectionChange)="historicalTenureChartOptionChange()">
            <mat-option
              *ngFor="let opt of tenureChartStatusOptions"
              [value]="opt"
              >{{opt}}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </mat-card-title>
      <mat-card-content>
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="tenureByMonthChartNoSeries"
          oneToOne="true"
          (chartInstance)="logChartInstance($event, 'tenureByMonthChart')"></highcharts-chart>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="cols-1 tw-my-5">
    <mat-card>
      <mat-card-title class="tw-flex tw-items-center">
        <div>Current Employee Tenure by</div>
        <mat-form-field appearance="outline">
          <mat-select
            [formControl]="selectedCurrentTenureChartOption"
            (selectionChange)="currentTenureChartOptionChange()">
            <mat-option
              *ngFor="let opt of currentTenureChartOptions"
              [value]="opt"
              >{{opt}}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </mat-card-title>
      <mat-card-content>
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="currentTenureChartNoSeries"
          oneToOne="true"
          (chartInstance)="logChartInstance($event, 'currentTenureChart')"></highcharts-chart>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="cols-1 tw-my-5">
    <mat-card>
      <mat-card-title>Current Employee Functional Distribution </mat-card-title>
      <mat-card-content>
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="treeChartOptions"
          oneToOne="true"
          (chartInstance)="logChartInstance($event, 'orgChart')"></highcharts-chart>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="cols-2 tw-mt-5">
    <mat-card>
      <mat-card-title>Previous and Next Companies</mat-card-title>
      <mat-card-subtitle
        >For arrivals and departures between {{startDateDate | date: 'MMM YY'}} and
        {{endDateDate | date: 'MMM YY'}}</mat-card-subtitle
      >
      <mat-card-content class="fullHeight">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="sankeyChartNoSeries"
          oneToOne="true"
          (chartInstance)="logChartInstance($event, 'companySankey')"></highcharts-chart>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>Current US Employee Distribution</mat-card-title>
      <mat-card-content class="fullHeight">
        <highcharts-chart
          [Highcharts]="Highcharts"
          constructorType="mapChart"
          [options]="mapChartNoData"
          oneToOne="true"
          (chartInstance)="logChartInstance($event, 'mapChart')"></highcharts-chart>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="cols-2 tw-mt-5">
    <mat-card>
      <mat-card-title>Current Employee Previous Companies</mat-card-title>
      <mat-card-content class="fullHeight">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="top10PreviousCompanyChartNoSeries"
          oneToOne="true"
          (chartInstance)="logChartInstance($event, 'top10PreviousCompany')"></highcharts-chart>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>Current Employee Schools</mat-card-title>
      <mat-card-content class="fullHeight">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="top10PreviousCompanyChartNoSeries"
          oneToOne="true"
          (chartInstance)="logChartInstance($event, 'top10School')"></highcharts-chart>
      </mat-card-content>
    </mat-card>
  </div>
</div>
