import { Component, OnInit } from '@angular/core';
import { ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import {
  AdminService,
  ListAllReportDefinitionsRequest,
  ReportDefinition,
  ReportsService,
} from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-report-definitions',
  templateUrl: './report-definitions.component.html',
  styleUrls: ['./report-definitions.component.scss'],
})
export class ReportDefinitionsComponent implements OnInit {
  refreshing: boolean = false;
  reportDefinitions: ReportDefinition[] = [];
  public colDefs: ColDef[] = [
    { field: 'id', maxWidth: 60 },
    { field: 'name' },
    { field: 'org_id', maxWidth: 110, filter: 'agSetColumnFilter' },
    { field: 'created_at', maxWidth: 230 },
    { field: 'deleted_at', maxWidth: 230 },
    {
      field: 'source.name',
      headerName: 'Source',
      valueGetter: (params: ValueGetterParams) =>
        params.data.source.name +
        ' ' +
        (params.data.source_id === 2 ? '(' + params.data.ledger_id + ')' : ''),
    },
    {
      field: 'filters',
      headerName: 'Filters',
      valueGetter: (params: ValueGetterParams) =>
        params.data.filters.map((filter: any) => filter.field).join(', '),
    },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    flex: 1,
  };

  constructor(
    private reportDefinitionService: AdminService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.getReportDefinitions();
  }

  getReportDefinitions(): void {
    this.refreshing = true;
    let req: ListAllReportDefinitionsRequest = {
      size: 1000,
    };
    this.reportDefinitionService.listAllReportDefinitions(req).subscribe({
      next: (data) => {
        this.reportDefinitions = data.items;
        if (data.pagination_token) {
          this.notify.warning('Only first 1000 destinations are displayed, but there are more!');
        }
        this.refreshing = false;
      },
      error: (error) => {
        this.notify.error('Failed to load report definitions');
        this.refreshing = false;
      },
    });
  }
}
