import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { ICellRendererParams } from 'ag-grid-community';
import { Destination } from 'ldt-data-deliveries-api';

interface DetailsCellRendererParams {
  params: ICellRendererParams;
  orgId: string;
  value: {};
  data: Destination;
}

@Component({
  selector: 'app-details-tooltip-cell',
  template: `
  <div *ngIf="params">
    <ul class="config-info-list tw-list-none tw-ml-0 tw-my-2 tw-pl-0">
      <li *ngFor="let param of paramsForDisplay" class="tw-leading-5 tw-flex tw-items-center tw-relative" matTooltip="Copy {{param.key}}" matTooltipPosition="above" (click)="copyToClipboard(param.value)">
        <span class="tw-inline-block tw-mr-2">{{ param.key }}:</span>
        <span class="tw-inline-block code-font-direct tw-truncate tw-max-w-[200px]">
          {{ param.value }}
        </span>
      </li>
    </ul>
  </div>
  `,
  styles: [],
})
export class DetailsTooltipCellComponent {
  @Input() params: DetailsCellRendererParams;
  @Input() orgId: string;
  paramsForDisplay: any = [];

  constructor(
    private clipboard: Clipboard,
    private notify: NotificationService
  ) {}

  agInit(params: DetailsCellRendererParams): void {
    this.params = params;
    this.orgId = params.orgId;
    this.generateContent(params);
  }

  generateContent(params: DetailsCellRendererParams): void {
    const keyNameMapping: Record<string, string> = {
      role_arn: 'Role ARN',
      bucket_identifier: 'Bucket ID',
      external_id: 'External ID',
      bucket_key: 'Object Prefix',
    };

    if (params.value) {
      this.paramsForDisplay = Object.entries(params.value).map(([key, value]) => {
        return {
          key: keyNameMapping[key] || key,
          value: this.formatValueForDisplay(key, value),
        };
      });
    }

    if (params.data.destination_type_id === 1) {
      this.paramsForDisplay.push({
        key: 'Bucket Path',
        value: this.getFormattedS3Uri(params.data.id, this.orgId),
      });

      this.paramsForDisplay.push({
        key: 'Access Point ARN',
        value: this.getFormattedAccessPointARN(params.data.id, this.orgId),
      });
    }
  }

  copyToClipboard(value: string): void {
    this.clipboard.copy(value);
    this.notify.success('Copied to clipboard!');
  }

  formatValueForDisplay(key: string, value: any): string {
    if (value === null || value === undefined) {
      return '';
    }

    return value;
  }

  getFormattedS3Uri(destinationId: number, orgId: string): string {
    const formattedOrgId = orgId.replace('_', '-');
    return `s3://arn:aws:s3:us-east-1:100811426269:accesspoint/${formattedOrgId}-${destinationId}/${orgId}/`;
  }

  getFormattedAccessPointARN(destinationId: number, orgId: string): string {
    const formattedOrgId = orgId.replace('_', '-');
    return `arn:aws:s3:us-east-1:100811426269:accesspoint/${formattedOrgId}-${destinationId}`;
  }
}
