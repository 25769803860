<h1 mat-dialog-title class="tw-px-0">
  {{data.title}}
</h1>

<div mat-dialog-content>
  <p class="tw-mb-4">{{data.message}}</p>
</div>

<div mat-dialog-actions>
  <button mat-stroked-button [mat-dialog-close]="false">No</button>
  <button mat-raised-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
</div>
