import { Component } from '@angular/core';
import { DefaultService } from 'ldt-moneyball-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-moneyball',
  templateUrl: './moneyball.component.html',
  styleUrls: ['./moneyball.component.scss'],
})
export class AdminMoneyballComponent {
  constructor(
    private moneyballService: DefaultService,
    private notify: NotificationService
  ) {}

  flushCache() {
    this.moneyballService.postAdminFlushCache().subscribe({
      next: () => {
        this.notify.success('Cache flushed');
      },
      error: (err) => {
        this.notify.error('Error flushing cache');
      },
    });
  }
}
