import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestinationsService, Destination } from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { TestStatusBadgeComponent } from 'src/app/shared/test-status-badge/test-status-badge.component';

@Component({
  selector: 'app-test-status-dialog',
  templateUrl: './test-status-dialog.component.html',
  styleUrls: ['./test-status-dialog.component.scss'],
  host: { class: 'ldt-modal-component' },
})
export class TestStatusDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TestStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { destinationData: Destination },
    private destinationsService: DestinationsService,
    private notify: NotificationService
  ) {}

  paramsForDisplay: any = [];

  testStatus: string;
  testStatusLabel: string = 'Destination Status';
  isLoading: boolean = false;
  @ViewChild(TestStatusBadgeComponent) testStatusBadgeComponent!: TestStatusBadgeComponent;

  ngOnInit(): void {
    const keyNameMapping: Record<string, string> = {
      role_arn: 'Role ARN',
      bucket_identifier: 'Bucket ID',
      external_id: 'External ID',
      bucket_key: 'Object Prefix',
    };

    if (this.data.destinationData.config) {
      this.paramsForDisplay = Object.entries(this.data.destinationData.config).map(
        ([key, value]) => {
          return {
            key: keyNameMapping[key] || key,
            value: this.formatValueForDisplay(key, value),
          };
        }
      );
    }

    if (this.data.destinationData.destination_type_id === 1) {
      this.paramsForDisplay.push({
        key: 'Bucket Path',
        value: this.getFormattedS3Uri(
          this.data.destinationData.id,
          this.data.destinationData.org_id
        ),
      });

      this.paramsForDisplay.push({
        key: 'Access Point ARN',
        value: this.getFormattedAccessPointARN(
          this.data.destinationData.id,
          this.data.destinationData.org_id
        ),
      });
    }
  }

  /**
   * Tests the status of a destination.
   * @param orgId - The ID of the organization.
   * @param destinationData - destination object data
   */
  testDestinationStatus(orgId: string, destinationData: Destination) {
    this.isLoading = true;
    this.testStatus = 'Loading...';

    if (!orgId || !destinationData.id) {
      console.error('Invalid orgId or destinationId:', orgId, destinationData.id);
      this.notify.error(
        'Something went wrong with getting the destination status. Please try again later.'
      );
      return;
    }

    this.destinationsService.testDestination(orgId, destinationData.id).subscribe({
      next: (data) => {
        this.isLoading = false;
        if (data.status) {
          this.testStatus = data.status;
          this.testStatusBadgeComponent.updateTimeStamp(new Date().toISOString());
          this.testStatusBadgeComponent.updateStatusBadge(data.status);
        }
      },
      error: (error) => {
        console.error('Error testing destination:', error);
        this.isLoading = false;
        this.testStatus = error.error?.message || 'Error testing destination.';
        this.testStatusBadgeComponent.updateTimeStamp(destinationData.tested_at || '');
      },
    });
  }

  formatValueForDisplay(key: string, value: any): string {
    if (value === null || value === undefined) {
      return '';
    }

    return value;
  }

  getFormattedS3Uri(destinationId: number, orgId: string): string {
    const formattedOrgId = orgId.replace('_', '-');
    return `s3://arn:aws:s3:us-east-1:100811426269:accesspoint/${formattedOrgId}-${destinationId}/${orgId}/`;
  }

  getFormattedAccessPointARN(destinationId: number, orgId: string): string {
    const formattedOrgId = orgId.replace('_', '-');
    return `arn:aws:s3:us-east-1:100811426269:accesspoint/${formattedOrgId}-${destinationId}`;
  }

  closeDialog(): void {
    this.dialogRef.close({ status: this.testStatus });
  }
}
