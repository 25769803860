import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/service/auth.service';
import { NotificationService } from '../../shared/notification-service/notification.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IntegrationDialogComponent } from './integration-dialog/integration-dialog.component';
import { ComponentType } from '@angular/cdk/portal';

export interface Integration {
  name: string;
  description: string;
  logo: string;
  buttonText: string;
  buttonAction: string;
  available: boolean;
  tags?: string[];
  order?: number;
}

@Component({
  selector: 'available-integrations',
  templateUrl: './available-integrations.component.html',
  styleUrls: ['./available-integrations.component.scss'],
})
export class AvailableIntegrationsComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private notify: NotificationService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  orgId: string = '';
  webhookUrl: string = 'https://eo5v6u7ymxftar7.m.pipedream.net';

  integrations: Integration[] = [
    {
      name: 'Salesforce',
      description:
        'Update your Salesforce records when jobs changes are detected, and sync your SFDC contacts to Live Data to keep them updated.',
      logo: 'https://www.salesforce.com/news/wp-content/uploads/sites/3/2021/05/Salesforce-logo.jpg',
      buttonText: 'Learn More',
      buttonAction: 'https://docs.gotlivedata.com/docs/salesforce-integration',
      available: true,
      tags: ['delivery', 'ingest'],
      order: 3,
    },
    {
      name: 'Hubspot CRM',
      description:
        'Update your Hubspot records when jobs changes are detected, and sync your Hubspot contacts to Live Data to keep them updated.',
      logo: 'https://dwglogo.com/wp-content/uploads/2015/12/HubSpot-Symbol.png',
      buttonText: "I'm Interested",
      buttonAction: 'webhook',
      available: false,
    },
    {
      name: 'MS Dynamics',
      description:
        'Update your contact records when jobs changes are detected, and sync your Dynamics contacts to Live Data to keep them updated.',
      logo: 'https://news.microsoft.com/wp-content/uploads/2013/07/DynamicsLogoVertical_Web.jpg',
      buttonText: "I'm Interested",
      buttonAction: 'webhook',
      available: false,
    },
    {
      name: 'Snowflake',
      description:
        'Consume job change data directly into your Snowflake instance to ensure your CDP is always accurate.',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fdocs.snowflake.com%2Fja%2F_images%2Flogo-snowflake-sans-text.png&f=1&nofb=1&ipt=fa0076f7a8a70a773bd80f7f5e7c77b3c731b4d7c4901f71c388738f5d05279e&ipo=images',
      buttonText: 'Talk to us',
      buttonAction: 'webhook',
      available: true,
      tags: ['delivery'],
      order: 2,
    },
    {
      name: 'Slack',
      description: 'Get real-time notifications in Slack when job changes are detected.',
      logo: 'https://cdn.freebiesupply.com/logos/large/2x/slack-logo-icon.png',
      buttonText: 'Configure',
      buttonAction:
        'https://slack.com/oauth/v2/authorize?client_id=242284372213.4510118636951&scope=incoming-webhook&state=' +
        this.auth.getSelectedOrgIdValue +
        '|' +
        this.auth.getUserValue.id,
      available: true,
      tags: ['notification'],
      order: 4,
    },
    {
      name: 'Webhooks',
      description:
        'Get real-time notifications to your webhook endpoint when job changes are detected. ',
      logo: 'https://cdn.freebiesupply.com/logos/thumbs/2x/webhooks-logo.png',
      buttonText: 'Configure',
      buttonAction: 'https://docs.gotlivedata.com/docs/webhook-notifications',
      available: true,
      tags: ['notification'],
      order: 5,
    },
    {
      name: 'Marketo',
      description: 'Create new Marketo leads when any of your contacts moves to a new company.',
      logo: 'https://capacity.com/wp-content/uploads/2020/11/marketo.jpeg.webp',
      buttonText: "I'm Interested",
      buttonAction: 'webhook',
      available: false,
    },
    {
      name: 'Zapier',
      description:
        'Connect Live Data to any of the 3,000+ apps to trigger actions on contact changes and get new contacts into Live Data.',
      logo: 'https://marketplace.digimind.com/hubfs/Website%20Visual%20assets%20S2/Digimind%20Website%20-%20Marketplace/Logos/Zapier_ICON-WithBG.png',
      buttonText: 'Configure',
      buttonAction: 'https://zapier.com/apps/live-data-technologies/integrations',
      available: true,
      tags: ['api'],
    },
    {
      name: 'AWS S3',
      description: "Configure data exchange via AWS S3 using either your or Live Data's S3 bucket.",
      logo: 'https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg',
      buttonText: 'Configure',
      buttonAction: '',
      available: true,
      tags: ['delivery', 'ingest'],
      order: 1,
    },
  ];

  availableIntegrations = this.integrations
    .filter((int) => int.available)
    .sort((a, b) => (a.order || 1000) - (b.order || 1000));
  comingSoonIntegrations = this.integrations
    .filter((int) => !int.available)
    .sort((a, b) => (a.order || 1000) - (b.order || 1000));

  ngOnInit(): void {
    let routeOrg = this.route.parent?.snapshot.paramMap.get('orgId');
    if (routeOrg) {
      this.orgId = routeOrg;
    }
  }

  /**
   * Handles the click event for an integration to record the interest.
   * @param int - integration object that was clicked.
   */
  private integrationClick(int: Integration) {
    let body: {} = {
      cardName: int.name,
      user: this.auth.getUserValue,
      org: this.auth.getOrgsValue.find((o) => o.id === this.auth.getSelectedOrgIdValue),
    };
    this.http.post(this.webhookUrl, body).subscribe(() => {
      // Do nothing
    });
    if (int.buttonAction === 'webhook') {
      this.notify.success(
        "Thank you for noting your interest in this integration. We'll be in touch shortly."
      );
    } else if (int.buttonAction === 'intercom') {
      (window as any)['Intercom']('showNewMessage');
    } else {
      window.open(int.buttonAction, '_blank');
    }
  }

  // called from the template
  private handleIntegrationClick(integration: Integration): void {
    if (integration.name === 'AWS S3') {
      this.openIntegrationDialog(IntegrationDialogComponent, integration);
    } else {
      this.integrationClick(integration);
    }
  }

  private openIntegrationDialog(
    dialogComponent: ComponentType<IntegrationDialogComponent>,
    integration: Integration
  ): void {
    try {
      const dialogRef = this.dialog.open(dialogComponent, {
        panelClass: 'ldt-custom-modal', // dimensions are set in styles.scss using this class
        data: {
          orgId: this.orgId,
          integration,
        },
      });
    } catch (error) {
      console.error('Error opening dialog:', error);
    }
  }
}
